<template>
  <div>
    <p class="subtitle has-text-left">
      {{
        $t("Components.Reservations.ReservationDeskspaces.Subtitle_Deskspaces")
      }}
    </p>
    <transition name="zoom-fade" mode="out-in">
      <table v-if="showDeskspaces" class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th width="100">
              {{
                $t("Components.Reservations.ReservationDeskspaces.Table_Seats")
              }}
            </th>
            <th>
              {{
                $t("Components.Reservations.ReservationDeskspaces.Table_Name")
              }}
            </th>
            <th>
              {{
                $t("Components.Reservations.ReservationDeskspaces.Table_Date")
              }}
            </th>
            <th width="100" class="has-text-right">
              {{
                $t("Components.Reservations.ReservationDeskspaces.Table_Price")
              }}
            </th>
            <th width="100" class="has-text-right">
              {{
                $t("Components.Reservations.ReservationDeskspaces.Table_Total")
              }}
            </th>
            <th width="25">&nbsp;</th>
            <th width="25">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(deskspace, index) in reservation.Spaces" :key="index">
            <td>{{ deskspace.Seats }}x</td>
            <td>{{ deskspace.SpaceName }}</td>
            <td>{{ deskspace.StartDate }} - {{ deskspace.EndDate }}</td>
            <td class="has-text-right">
              <span v-html="reservation.CurrencySymbol"></span>
              <span>{{ deskspace.PricePerSeat.toFixed(2) }}</span>
            </td>
            <td class="has-text-right">
              <span v-html="reservation.CurrencySymbol"></span>
              <span>{{ deskspace.PriceTotal.toFixed(2) }}</span>
            </td>
            <td width="25">
              <a @click="setEditSpace(deskspace, index)" class="button">
                <span class="icon">
                  <i class="far fa-edit"></i>
                </span>
              </a>
            </td>
            <td width="25">
              <a @click="setDeleteSpace(deskspace, index)" class="button">
                <span class="icon">
                  <i class="far fa-trash-alt"></i>
                </span>
              </a>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <a @click="addDeskspace" class="button is-success  is-small">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span>{{
                  $t(
                    "Components.Reservations.ReservationDeskspaces.Icon_AddDeskspace"
                  )
                }}</span>
              </a>
            </td>
          </tr>
          <tr>
            <td colspan="5">
              {{
                $t(
                  "Components.Reservations.ReservationDeskspaces.Table_Subtotal"
                )
              }}
            </td>
            <td class="has-text-right">
              <span v-html="reservation.CurrencySymbol"></span>
              <span>{{ total.toFixed(2) }}</span>
            </td>
            <td colspan="2">&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </transition>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus/event-bus";

export default {
  props: {
    reservation: null
  },

  data() {
    return {
      editSpace: null,
      showEditDeskspace: false,
      showDeskspaces: true
    };
  },

  computed: {
    total() {
      let total = 0.0;

      if (this.reservation.Spaces.length > 0) {
        for (let i = 0; i <= this.reservation.Spaces.length; i++) {
          let meetingspace = this.reservation.Spaces[i];
          if (meetingspace && meetingspace.PriceTotal > 0) {
            total = total + meetingspace.PriceTotal;
          }
        }
      }

      return total;
    }
  },

  created() {
    // EventBus.$on('spaceSelected', meetingspace => {
    //   this.setShowMeetingspaces()
    // })
    // EventBus.$on('updateSpace', meetingspace => {
    //   this.updateSpace(meetingspace)
    // })
  },

  methods: {
    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60));
      let minute = Number(minutes - hour * 60);

      return (
        (hour < 10 ? "0" : "") + hour + ":" + (minute < 10 ? "0" : "") + minute
      );
    },

    cancelEditSpace() {
      this.editSpace = null;
      this.setShowMeetingspaces();
    },

    updateMeetingspace(space) {
      let ms = this.reservation.Spaces[space.Index];
      ms.Seats = Number(space.Seats);
      ms.SettingId = space.SettingId;
      ms.PricePerSeat = Number(space.PricePerSeat);
      ms.PriceTotal = Number(space.PriceTotal);
      ms.Crc = space.Crc;
      ms.StartMinutes = Number(space.StartMinutes);
      ms.EndMinutes = Number(space.EndMinutes);

      this.setShowMeetingspaces();
    },

    addDeskspace() {
      this.$emit("showAddDeskspace");
    },

    setEditSpace(deskspace, index) {
      deskspace.Index = index;
      this.editSpace = JSON.parse(JSON.stringify(deskspace));
      this.setShowEditDeskspace();
    },

    setDeleteSpace(deskspace, index) {
      if (deskspace.Id > 0) {
        EventBus.$emit("spaceDeleted", deskspace);
      }

      this.reservation.Spaces.splice(index, 1);
    },

    setShowEditDeskspace() {
      this.setVisibleItem("editDeskspace");
    },

    setShowDeskspaces() {
      this.setVisibleItem("deskspaces");
    },

    setVisibleItem(item) {
      this.showEditDeskspace = item === "editDeskspace";
      this.showDeskspaces = item === "deskspaces";
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-space {
  backface-visibility: hidden;
}

.zoom-fade-enter,
.zoom-fade-leave-active {
  opacity: 0;
  //transform: scale(0);
}

.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: opacity 0.5s ease;
  //transform: scale(1) 1.5s ease;
}
</style>
